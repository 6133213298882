import React from 'react'
import Header from "../components/Header";
import SectionOne from "../../src/components/section-one";
import SectionTwo from "../../src/components/section-two";
import SectionThree from "../../src/components/section-three";
import SectionFour from "../../src/components/section-four";
import SectionFive from "../../src/components/section-five";
import SectionSix from "../../src/components/section-six";
import SectionSeven from "../../src/components/section-seven";
import Footer from "../components/footer";

const Home = () => {
    return (
        <div>
            <Header />
            <SectionOne />
            <SectionTwo />
            <SectionThree />
            <SectionFour />
            <SectionFive />
            <SectionSix />
            <SectionSeven />
            <Footer />
        </div>
    )
}

export default Home