import React from 'react'
import Header from "./Header";
import Footer from "./footer";
import { Link } from "react-router-dom";

const SignUp = () => {
  return (
    <>
    
    <Header/>

    {/* <!-- main-area --> */}
    <main className="main--area">

        {/* <!-- breadcrumb-area --> */}
        <section className="breadcrumb-area breadcrumb__hide-img" data-background="assets/img/bg/breadcrumb_bg02.jpg">
            <div className="container">
                <div className="breadcrumb__wrapper">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb__content">
                                <h2 className="title">Sign Up</h2>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to ="/">Home</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">sign up</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- breadcrumb-area-end --> */}

        {/* <!-- contact-area --> */}
        <section className="contact-area">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-3">
                      </div>
                    <div className="col-lg-6">

<div className="row freelacer-btn">
<div className="col-sm-6 wf"><a href="#">Work As a Freelancer</a></div>
<div className="col-sm-6 hf"><a href="#">Hire A Freelancer</a></div>
						
						
						</div>

					<div className="login__separator"><span>Register Here</span></div>
					
					
                        <div className="contact__form-wrap">
                            <form id="contact-form" action="#" method="POST">
                                <div className="row">
								<div className="col-sm-12">
                                        <div className="input-grp">
                                            <input name="name" type="text" placeholder="Full Name *" required/>
                                        </div>
                                    </div>
                                   <div className="col-sm-12">
                                        <div className="input-grp">
                                            <input name="email" type="email" placeholder="Email *" required/>
                                        </div>
                                    </div>
									<div className="col-sm-12">
                                        <div className="input-grp">
                                            <input name="password" type="Password" placeholder="Password" required/>
                                        </div>
                                    </div>
									
									
									<div className="col-sm-12">
									</div>
                                </div>



                                <button className="submit-btn">Sign Up</button>
                            </form>
							 <p className="ajax-response"></p><br/>
							
							
                        </div>
						
						
                    </div>
					<div className="col-lg-3">
                      </div>
                </div>
            </div>
        </section>
        {/* <!-- contact-area-end --> */}

  

    </main>
    {/* <!-- main-area-end --> */}


<Footer/>

    </>
  )
}

export default SignUp