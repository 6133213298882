import React from "react";
import Header from "./Header";
import Footer from "./footer";
import nft_img04 from "../../src/img/nft/nft_img04.jpg";
import imgClient from "../../src/img/team/team04.png";

const ProductSingle = () => {
  return (
    <>
      <Header />
      <main class="main--area">
        {/* <!-- breadcrumb-area --> */}
        <section
          class="breadcrumb-area breadcrumb__hide-img"
          data-background="assets/img/bg/breadcrumb_bg02.jpg"
        >
          <div class="container">
            <div class="breadcrumb__wrapper">
              <div class="row">
                <div class="col-12">
                  <div class="breadcrumb__content">
                    <h2 class="title">product single</h2>
                    <nav aria-label="breadcrumb">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                          <a href="index.html">Home</a>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                          product single
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- breadcrumb-area-end --> */}

        {/* <!-- shop-area --> */}
        <section class="shop-area shop-details-area">
          <div class="container">
            <div class="row">
              <div class="shop__details-images-wrap">
                <div class="tab-content" id="imageTabContent">
                  <div
                    class="tab-pane show active"
                    id="one"
                    role="tabpanel"
                    aria-labelledby="one-tab"
                  >
                    <a href="img/nft/nft_img04.jpg" class="popup-image">
                      <img src={nft_img04} alt="img" />
                    </a>
                  </div>
                </div>
              </div>
              <div class="shop__details-content">
                <h2 class="title">$25.45</h2>
                <div class="shop__details-qty">
                  <div class="cart-plus-minus d-flex flex-wrap align-items-center">
                    <form action="#" class="quantity num-block">
                      <input type="text" class="in-num" value="1" readonly="" />
                      <div class="qtybutton-box">
                        <span class="plus">
                          <i class="fas fa-angle-up"></i>
                        </span>
                        <span class="minus dis">
                          <i class="fas fa-angle-down"></i>
                        </span>
                      </div>
                    </form>
                    <button class="shop__details-cart-btn">Hire</button>
                  </div>
                </div>
                <hr />
                <div class="shop__details-bottom">
                  <div class="posted_in">
                    <a href="#">
                      <i class="fas fa-paper-plane"></i> Delivery in 4 days
                    </a>
                    <a href="#">
                      <i class="far fa-thumbs-up"></i> Rating 99% (23 reviews)
                    </a>
                    <a href="#">
                      <i class="far fa-clock"></i>Response time within a day
                    </a>
                  </div>
                </div>
                <hr />

                <div class="shop__details-model d-flex align-items-center">
                  <p class="model m-0">
                    <i class="fas fa-map-marker-alt"></i> UNITED KINGDOM
                  </p>
                  <ul class="list-wrap d-flex align-items-center">
                    <li class="active">Contact</li>
                  </ul>
                </div>
                <div class="shop__details-short-description d-flex">
                  <img
                    src={imgClient}
                    class="rounded-circle mr-5"
                    alt="img"
                    width="50"
                    height="50"
                  />{" "}
                  <p>
                    Lorem ipsum dolor sit amet, consteur adipiscing Duis
                    elementum solliciin is yaugue euismods Nulla ullaorper.
                  </p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="product__desc-wrap">
                  <ul class="nav nav-tabs" id="descriptionTab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link active"
                        id="description-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#description"
                        type="button"
                        role="tab"
                        aria-controls="description"
                        aria-selected="true"
                      >
                        Description
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="info-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#info"
                        type="button"
                        role="tab"
                        aria-controls="info"
                        aria-selected="false"
                      >
                        Buyer Tips
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        class="nav-link"
                        id="reviews-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#reviews"
                        type="button"
                        role="tab"
                        aria-controls="reviews"
                        aria-selected="false"
                      >
                        How it works
                      </button>
                    </li>
                  </ul>
                  <div class="tab-content" id="descriptionTabContent">
                    <div
                      class="tab-pane animation-none fade show active"
                      id="description"
                      role="tabpanel"
                      aria-labelledby="description-tab"
                    >
                      <h2>What you get with this Offer</h2>
                      <p>
                        Hi great buyers.I am here to provide claps for your
                        medium article which will boost, rank your article and
                        make it well known.here, your article will be promoted
                        to the audience where the claps will come from
                        organically and this will also make your article go
                        viral.I will Use 200 USA Users To Add 200 Medium
                        Claps.Buy Medium claps with our service:
                      </p>
                      <p>
                        Claps from real users
                        <br />
                        If you want less than 100 claps we can make it
                        <br />
                        Max order 2000 claps
                        <br />
                        Non dropProfile Promotion
                        <br />
                        Articles promotion
                        <br />
                        Speed 1500 - 2000 claps per day
                        <br />
                        For more Claps please use multiple quantity orders
                        <br />
                        Cheap Service
                        <br />
                        Worldwide traffic
                        <br />
                        Real & Organic followers
                        <br />
                        Fast Delivery
                        <br />
                        24/7 Support
                      </p>
                      <p>
                        First, you Order my 200+ Claps Pack. If you satisfied my
                        work order a Large pack.Note: If you have any questions,
                        please Ask me or send me your message.Thank You so much
                        for visiting my GIG!Regards,Sopna.
                      </p>
                      <p>
                        Don't look even slightly believable. If you are going to
                        use a passage of Lorem Ipsum, you need to be sure there
                        isn't anything embarrassing hidden in the middle of
                        text. All the Lorem Ipsum generators on the Internet
                        tend to repeat predefined chunks as necessary, making
                        this the first true generator on the Internet. It uses a
                        dictionary of over 200 Latin words, combined with a
                        handful of model sentence structures, to generate Lorem
                        Ipsum which looks reasonable. The generated Lorem Ipsum
                        is therefore always free from repetition, injected
                        humour.
                      </p>
                    </div>
                    <div
                      class="tab-pane animation-none fade"
                      id="info"
                      role="tabpanel"
                      aria-labelledby="info-tab"
                    >
                      <p>
                        The Offer price is fixed - you never pay a penny
                        moreYour money is safe until you agree to release funds
                        to the Freelancer After purchase, you should contact the
                        Freelancer and let them know about your requirements
                      </p>
                    </div>
                    <div
                      class="tab-pane animation-none fade"
                      id="reviews"
                      role="tabpanel"
                      aria-labelledby="reviews-tab"
                    >
                      <div class="product__desc-review">
                        <div class="left-rc">
                          <p>
                            You buy an Offer and your payment is held in escrow
                            You contact the Freelancer and specify your
                            requirements Work is delivered If you are happy you
                            release the money to the Freelancer
                          </p>
                        </div>

                        <div class="right-rc">
                          <a href="#">Read More</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="related__product-area">
              <div class="related__product-wrapper">
                <h2 class="related-title">Related Products</h2>
                <div class="row justify-content-center row-cols-xl-4 row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1">
                  <div class="col">
                    <div class="shop__item">
                      <div class="shop__item-thumb">
                        <a href="shop-details.html">
                          <img src={nft_img04} alt="img" />
                        </a>
                        <a href="#" class="wishlist-button">
                          <i class="far fa-heart"></i>
                        </a>
                      </div>
                      <div class="shop__item-line"></div>
                      <div class="shop__item-content">
                        <div class="shop__item-content-top">
                          <h4 class="title">
                            <a href="shop-details.html">VFX Animation</a>
                          </h4>
                          <div class="shop__item-price">$29</div>
                        </div>
                        <div class="shop__item-cat">
                          <a href="#">E-SPORTS</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="shop__item">
                      <div class="shop__item-thumb">
                        <a href="shop-details.html">
                          <img src={nft_img04} alt="img" />
                        </a>
                        <a href="#" class="wishlist-button">
                          <i class="far fa-heart"></i>
                        </a>
                      </div>
                      <div class="shop__item-line"></div>
                      <div class="shop__item-content">
                        <div class="shop__item-content-top">
                          <h4 class="title">
                            <a href="shop-details.html">VFX Animation</a>
                          </h4>
                          <div class="shop__item-price">$69</div>
                        </div>
                        <div class="shop__item-cat">
                          <a href="#">accessories</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="shop__item">
                      <div class="shop__item-thumb">
                        <a href="shop-details.html">
                          <img src={nft_img04} alt="img" />
                        </a>
                        <a href="#" class="wishlist-button">
                          <i class="far fa-heart"></i>
                        </a>
                      </div>
                      <div class="shop__item-line"></div>
                      <div class="shop__item-content">
                        <div class="shop__item-content-top">
                          <h4 class="title">
                            <a href="shop-details.html">VFX Animation</a>
                          </h4>
                          <div class="shop__item-price">$39</div>
                        </div>
                        <div class="shop__item-cat">
                          <a href="#">E-SPORTS</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="shop__item">
                      <div class="shop__item-thumb">
                        <a href="shop-details.html">
                          <img src={nft_img04} alt="img" />
                        </a>
                        <a href="#" class="wishlist-button">
                          <i class="far fa-heart"></i>
                        </a>
                      </div>
                      <div class="shop__item-line"></div>
                      <div class="shop__item-content">
                        <div class="shop__item-content-top">
                          <h4 class="title">
                            <a href="shop-details.html">VFX Animation</a>
                          </h4>
                          <div class="shop__item-price">$49</div>
                        </div>
                        <div class="shop__item-cat">
                          <a href="#">accessories</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- shop-area-end --> */}
      </main>
      <Footer />
    </>
  );
};

export default ProductSingle;
