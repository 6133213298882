import React, { useEffect } from 'react';
import Swiper from 'swiper';
import FireLogo from "../../src/img/icons/fire.png";
import nft_avatar01 from "../../src/img/nft/nft_avatar01.png";
import nft_avatar02 from "../../src/img/nft/nft_avatar02.png";
import nft_avatar03 from "../../src/img/nft/nft_avatar03.png";
import nft_img04 from "../../src/img/nft/nft_img04.jpg";
import nft_img05 from "../../src/img/nft/nft_img05.jpg";
import nft_img06 from "../../src/img/nft/nft_img06.jpg";
import nft_img07 from "../../src/img/nft/nft_img07.jpg";


function SectionSeven() {
  useEffect(() => {
    new Swiper('.trendingNft-active', {
      loop: true,
      observer: true,
      observeParents: true,
      slidesPerView: 3,
      spaceBetween: 30,
      breakpoints: {
        1500: { slidesPerView: 3 },
        1200: { slidesPerView: 3 },
        992: { slidesPerView: 2 },
        768: { slidesPerView: 2 },
        576: { slidesPerView: 1 },
        0: { slidesPerView: 1 }
      },
      navigation: {
        nextEl: '.slider-button-next',
        prevEl: '.slider-button-prev'
      }
    });
  }, []);

  return (
    <section className="trendingNft-area section-pt-120 section-pb-90">
      <div className="container">
        <div className="trendingNft__title-wrap">
          <div className="row">
            <div className="col-md-7">
              <div className="trendingNft__title">
                <h2 className="title">
                  Trending Projects
                  {/* <img src="img/icons/fire.png" width="35" alt="icon" /> */}
                  <img src= {FireLogo} width="35" alt="icon" />
                </h2>
              </div>
            </div>
            <div className="col-md-5">
              <div className="trendingNft__nav">
                <button className="slider-button-prev">
                  <i className="fas fa-angle-left"></i>
                </button>
                <button className="slider-button-next">
                  <i className="fas fa-angle-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="swiper-container trendingNft-active">
          <div className="swiper-wrapper">
            <div className="swiper-slide">
              <div className="trendingNft__item">
                <div className="trendingNft__item-top">
                  <div className="trendingNft__item-avatar">
                    <div className="image">
                      {/* <a href="shop-details.html"><img src="img/nft/nft_avatar01.png" alt="img" /></a> */}
                      <a href="shop-details.html"><img src= {nft_avatar01} alt="img" /></a>
                    </div>
                    <div className="info">
                      <h6 className="name">Crypto Max</h6>
                      <a href="#" className="userName">@Jon Max</a>
                    </div>
                  </div>
                  <div className="trendingNft__item-wish">
                    <a href="#"><i className="far fa-heart"></i></a>
                  </div>
                </div>
                <div className="trendingNft__item-image">
                  {/* <a href="#"><img src="img/nft/nft_img04.jpg" alt="img" /></a> */}
                  <a href="#"><img src= {nft_img04} alt="img" /></a>
                </div>
                <div className="trendingNft__item-bottom">
                  <div className="trendingNft__item-price">
                    <span className="bid">Last Bid</span>
                    <h6 className="eth"><span>$</span>450</h6>
                  </div>
                  <a href="#" className="bid-btn">Bid <i className="fas fa-long-arrow-alt-right"></i></a>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="trendingNft__item">
                <div className="trendingNft__item-top">
                  <div className="trendingNft__item-avatar">
                    <div className="image">
                      {/* <a href="#"><img src="img/nft/nft_avatar02.png" alt="img" /></a> */}
                      <a href="#"><img src= {nft_avatar02} alt="img" /></a>
                    </div>
                    <div className="info">
                      <h6 className="name">Golden Crypto</h6>
                      <a href="#" className="userName">@Jon Max</a>
                    </div>
                  </div>
                  <div className="trendingNft__item-wish">
                    <a href="#"><i className="far fa-heart"></i></a>
                  </div>
                </div>
                <div className="trendingNft__item-image">
                  {/* <a href="#"><img src="img/nft/nft_img05.jpg" alt="img" /></a> */}
                  <a href="#"><img src= {nft_img05} alt="img" /></a>
                </div>
                <div className="trendingNft__item-bottom">
                  <div className="trendingNft__item-price">
                    <span className="bid">Last Bid</span>
                    <h6 className="eth"><span>$</span>450</h6>
                  </div>
                  <a href="#" className="bid-btn">Bid <i className="fas fa-long-arrow-alt-right"></i></a>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="trendingNft__item">
                <div className="trendingNft__item-top">
                  <div className="trendingNft__item-avatar">
                    <div className="image">
                      {/* <a href="#"><img src="img/nft/nft_avatar03.png" alt="img" /></a> */}
                      <a href="#"><img src= {nft_avatar03} alt="img" /></a>
                    </div>
                    <div className="info">
                      <h6 className="name">Black Crypto</h6>
                      <a href="#" className="userName">@Jon Max</a>
                    </div>
                  </div>
                  <div className="trendingNft__item-wish">
                    <a href="#"><i className="far fa-heart"></i></a>
                  </div>
                </div>
                <div className="trendingNft__item-image">
                  {/* <a href="#"><img src="img/nft/nft_img06.jpg" alt="img" /></a> */}
                  <a href="#"><img src= {nft_img06} alt="img" /></a>
                </div>
                <div className="trendingNft__item-bottom">
                  <div className="trendingNft__item-price">
                    <span className="bid">Last Bid</span>
                    <h6 className="eth"><span>$</span>450</h6>
                  </div>
                  <a href="#" className="bid-btn">Bid <i className="fas fa-long-arrow-alt-right"></i></a>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="trendingNft__item">
                <div className="trendingNft__item-top">
                  <div className="trendingNft__item-avatar">
                    <div className="image">
                      {/* <a href="#"><img src="img/nft/nft_avatar02.png" alt="img" /></a> */}
                      <a href="#"><img src= {nft_avatar02} alt="img" /></a>
                    </div>
                    <div className="info">
                      <h6 className="name">Luck Crypto</h6>
                      <a href="#" className="userName">@Jon Max</a>
                    </div>
                  </div>
                  <div className="trendingNft__item-wish">
                    <a href="#"><i className="far fa-heart"></i></a>
                  </div>
                </div>
                <div className="trendingNft__item-image">
                  {/* <a href="#"><img src="img/nft/nft_img07.jpg" alt="img" /></a> */}
                  <a href="#"><img src= {nft_img07}alt="img" /></a>
                </div>
                <div className="trendingNft__item-bottom">
                  <div className="trendingNft__item-price">
                    <span className="bid">Last Bid</span>
                    <h6 className="eth"><span>$</span>450</h6>
                  </div>
                  <a href="#" className="bid-btn">Bid <i className="fas fa-long-arrow-alt-right"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SectionSeven;