import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./components/Home";
import Animation from "./components/NavbarComponents/Animation";
import Gaming from "./components/NavbarComponents/Gaming";
import Producer from "./components/NavbarComponents/Producer";
import ProductionSupport from "./components/NavbarComponents/ProductionSupport";
import ResearchAndDevelopment from "./components/NavbarComponents/ResearchAndDevelopment";
import Vfx from "./components/NavbarComponents/Vfx";

function App() {
  return (
    <>
      <main className="main--area">
      <Home/>
        {/* <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/animation" element={<Animation />} />
            <Route path="/vfx" element={<Vfx />} />
            <Route path="/gaming" element={<Gaming />} />
            <Route path="/producer" element={<Producer />} />
            <Route path="/productionsupport" element={<ProductionSupport />} />
            <Route path="/r&d" element={<ResearchAndDevelopment />} />
            <Route
              path="*"
              element={<div> Not Found or You do not have permission.</div>}
            />
          </Routes>
        </BrowserRouter> */}
      </main>
    </>
  );
}

export default App;
