import React from "react";
import vfx_art from "../../src/img/vfx-art.png"

function SectionThree() {
  return (
    <section
      className="roadMap__area video-bg roadMap-bg section-pt-150 section-pb-150"
      data-background="img/bg/roadmap_bg.jpg"
    >
      <div className="container custom-container">
        <div className="row justify-content-center">
          <div className="roadMap__inner">
            <div className="row">
              <div className="col-xl-6 col-lg-6">
                <div className="roadMap__content">
                  <h2 className="title">Description of the problem</h2>
                  <p>
                    We understand the importance of high-quality visual effects
                    in bringing imagination to life. Our platform brings
                    together a diverse community of skilled VFX professionals,
                    ranging from experienced freelancers to established studios,
                    all ready to take on your VFX projects.
                    <br />
                    Are you in need of jaw-dropping visual effects that will
                    captivate your audience? Look no further! Our platform
                    allows you to post your VFX project, providing detailed
                    descriptions and requirements. Once your project is live,
                    our community of talented VFX professionals will submit
                    their bids, offering their expertise, cost estimates, and
                    proposed timelines. Lowest bid will be automatically
                    accepted and professional will be assigned to the project.
                  </p>
                  <a href="#" className="tg-btn-2">
                    <span>Read More</span>
                  </a>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 vfx-img">
                {/* <img src="img/vfx-art.png" alt="img" /> */}
                <img src= {vfx_art} alt="img" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SectionThree;
