import React from "react";
import team01 from "../../src/img/team/team01.png";
import team02 from "../../src/img/team/team02.png";
import team03 from "../../src/img/team/team03.png";
import team04 from "../../src/img/team/team04.png";

function SectionFive () {
return(

    <section className="team__area video-bg team-bg section-pt-130 section-pb-100" data-background="img/bg/team_bg.jpg">
    <div className="container">
        <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-7 col-md-10">
                <div className="section__title text-center mb-60">
     <span className="sub-title tg__animate-text">Search and contact freelancers directly</span>
                   <h3 className="title">Expert freelancers</h3>
                </div>
            </div>
        </div>
        <div className="row justify-content-center">
            <div className="col-xl-3 col-lg-4 col-sm-6 wow fadeInUp" data-wow-delay=".2s">
                <div className="team__item">
                    <div className="team__thumb">
                        {/* <a href="team-details.html"><img src="img/team/team01.png" alt="img" /></a> */}
                        <a href="team-details.html"><img src= {team01} alt="img" /></a>
                    </div>
                    <div className="team__content">
                        <h4 className="name"><a href="#">killer master</a></h4>
                        <span className="designation">VFX Artist</span>
                    </div>
                </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6 wow fadeInUp" data-wow-delay=".4s">
                <div className="team__item">
                    <div className="team__thumb">
                        {/* <a href="#"><img src="img/team/team02.png" alt="img" /></a> */}
                        <a href="#"><img src= {team02} alt="img" /></a>
                    </div>
                    <div className="team__content">
                        <h4 className="name"><a href="#">tanu mark</a></h4>
                        <span className="designation">VFX Artist</span>
                    </div>
                </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6 wow fadeInUp" data-wow-delay=".6s">
                <div className="team__item">
                    <div className="team__thumb">
                        {/* <a href="#"><img src="img/team/team03.png" alt="img" /></a> */}
                        <a href="#"><img src= {team03} alt="img" /></a>
                    </div>
                    <div className="team__content">
                        <h4 className="name"><a href="#">Thompson Scot</a></h4>
                        <span className="designation">Game Artist</span>
                    </div>
                </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6 wow fadeInUp" data-wow-delay=".8s">
                <div className="team__item">
                    <div className="team__thumb">
                        {/* <a href="#"><img src="img/team/team04.png" alt="img" /></a> */}
                        <a href="#"><img src= {team04} alt="img" /></a>
                    </div>
                    <div className="team__content">
                        <h4 className="name"><a href="#">Shakh Danial</a></h4>
                        <span className="designation">VFX Artist</span>
                    </div>
                </div>
            </div>
  
  
        </div>
    </div>
</section>


);

}

export default SectionFive