import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import LogoHeader from "../../src/img/logo/logo.png";

function Header() {
  useEffect(() => {
    if (
      document.querySelectorAll(".tgmenu__wrap li.menu-item-has-children ul")
        .length &&
      document.querySelectorAll(
        ".tgmenu__wrap .navigation li.menu-item-has-children"
      )
    ) {
      var dropdownBtns = document.querySelectorAll(
        ".tgmenu__wrap .navigation li.menu-item-has-children"
      );
      for (var i = 0; i < dropdownBtns.length; i++) {
        var dropdownBtn = dropdownBtns[i];
        var div = document.createElement("div");
        div.className = "dropdown-btn";
        div.innerHTML = '<span class="plus-line"></span>';
        dropdownBtn.appendChild(div);
      }
    }

    if (document.querySelector(".tgmobile__menu")) {
      var menuWrap = document.querySelector(".tgmenu__wrap .tgmenu__main-menu");
      var menuOuter = document.querySelector(
        ".tgmobile__menu .tgmobile__menu-box .tgmobile__menu-outer"
      );
      var menuHTML = menuWrap.innerHTML;
      menuOuter.innerHTML = menuHTML;

      var dropdownButtons = document.querySelectorAll(
        ".tgmobile__menu li.menu-item-has-children .dropdown-btn"
      );
      for (var a = 0; a < dropdownButtons.length; a++) {
        dropdownButtons[a].addEventListener("click", function () {
          this.classList.toggle("open");
          var ul = this.previousElementSibling;
          ul.style.display = ul.style.display === "none" ? "block" : "none";
        });
      }

      var mobileNavToggler = document.querySelector(".mobile-nav-toggler");
      mobileNavToggler.addEventListener("click", function () {
        document.body.classList.add("mobile-menu-visible");
      });

      var closeBtns = document.querySelectorAll(
        ".tgmobile__menu-backdrop, .tgmobile__menu .close-btn"
      );
      for (var j = 0; j < closeBtns.length; j++) {
        closeBtns[j].addEventListener("click", function () {
          document.body.classList.remove("mobile-menu-visible");
        });
      }
    }
  }, []);

  useEffect(() => {
    document.querySelectorAll("[data-background]").forEach(function (element) {
      element.style.backgroundImage =
        "url(" + element.getAttribute("data-background") + ")";
    });

    document.querySelectorAll("[data-bg-color]").forEach(function (element) {
      element.style.backgroundColor = element.getAttribute("data-bg-color");
    });

    var s = window,
      a = 0,
      t = document.querySelector("#sticky-header"),
      i = t.offsetHeight + 30;

    s.addEventListener("scroll", function () {
      var e = s.scrollY;
      if (e >= i) {
        t.classList.add("tg-sticky-menu");
      } else {
        t.classList.remove("tg-sticky-menu");
        t.classList.remove("sticky-menu__show");
      }

      if (t.classList.contains("tg-sticky-menu")) {
        if (e < a) {
          t.classList.add("sticky-menu__show");
        } else {
          t.classList.remove("sticky-menu__show");
        }
      }

      a = e;
    });

    window.addEventListener("scroll", function () {
      if (245 > window.scrollY) {
        document
          .querySelectorAll(".scroll-to-target")
          .forEach(function (element) {
            element.classList.remove("open");
          });
      } else {
        document
          .querySelectorAll(".scroll-to-target")
          .forEach(function (element) {
            element.classList.add("open");
          });
      }
    });

    document.querySelectorAll(".scroll-to-target").forEach(function (element) {
      element.addEventListener("click", function () {
        var target = element.getAttribute("data-target");
        window.scrollTo({
          top: document.querySelector(target).offsetTop,
          behavior: "smooth",
        });
      });
    });
  }, []);

  useEffect(() => {
    if (document.querySelectorAll(".tgmenu__action .search").length) {
      document
        .querySelectorAll(".tgmenu__action .search")
        .forEach(function (element) {
          element.addEventListener("click", function () {
            document.body.classList.add("search__active");
            return false;
          });
        });

      document.querySelectorAll(".search__close").forEach(function (element) {
        element.addEventListener("click", function () {
          document.body.classList.remove("search__active");
        });
      });
    }
    document.querySelectorAll(".side-toggle-icon").forEach(function (element) {
      element.addEventListener("click", function () {
        document.body.classList.add("offCanvas__menu-visible");
      });
    });

    document
      .querySelectorAll(".offCanvas__overlay, .offCanvas__toggle")
      .forEach(function (element) {
        element.addEventListener("click", function () {
          document.body.classList.remove("offCanvas__menu-visible");
        });
      });
  }, []);

  return (
    <header>
      <div id="sticky-header" className="tg-header__area transparent-header">
        <div className="container custom-container">
          <div className="row">
            <div className="col-12">
              <div className="mobile-nav-toggler">
                <i className="fas fa-bars"></i>
              </div>
              <div className="tgmenu__wrap">
                <nav className="tgmenu__nav">
                  <div className="logo">
                    {/* <a href="/">
                      <img src="img/logo/logo.png" alt="Logo" />
                    </a> */}
                    <Link to="/">
                      <img src={LogoHeader} alt="Logo" />
                    </Link>
                  </div>

                  <div className="tgmenu__navbar-wrap tgmenu__main-menu d-none d-xl-flex">
                    <ul className="navigation">
                      <li className="active">
                        <Link to="/animation">Animation</Link>
                      </li>
                      <li>
                        <Link to="/vfx">Vfx</Link>
                      </li>
                      <li>
                        <Link to="/gaming">Gaming</Link>
                      </li>
                      <li>
                        <Link to="/producer">Producer</Link>
                      </li>
                      <li>
                        <Link to="/productionsupport">Production Support</Link>
                      </li>
                      <li>
                        <Link to="/r&d">R&D</Link>
                      </li>
                    </ul>
                  </div>
                  {/* <Routes>
                    <Route path="/animation" element={<Animation />} />
                    <Route path="/vfx" element={<Vfx />} />
                    <Route path="/gaming" element={<Gaming />} />
                    <Route path="/producer" element={<Producer />} />
                    <Route
                      path="/productionsupport"
                      element={<ProductionSupport />}
                    />
                    <Route path="/r&d" element={<ResearchAndDevelopment />} />
                  </Routes> */}

                  <div className="tgmenu__action d-none d-md-block">
                    <ul className="list-wrap">
                      <li className="search">
                        <a href="#">
                          <i className="flaticon-search-1"></i>
                        </a>
                      </li>
                      <li className="header-btn">
                        <Link to = "/login" className="tg-border-btn">
                          <i className="flaticon-edit"></i> Login
                        </Link>
                      </li>
                      <li className="header-btn">
                        <Link to ="/signup" className="tg-border-btn">
                          <i className="flaticon-edit"></i> SIGN UP
                        </Link>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="tgmobile__menu">
                <nav className="tgmobile__menu-box">
                  <div className="close-btn">
                    <i className="flaticon-swords-in-cross-arrangement"></i>
                  </div>
                  <div className="nav-logo">
                    <a href="index.html">
                      {/* <img src="img/logo/logo.png" alt="Logo" /> */}
                      <img src= {LogoHeader}alt="Logo" />
                    </a>
                  </div>
                  <div className="tgmobile__search">
                    <form action="#">
                      <input type="text" placeholder="Search here..." />
                      <button>
                        <i className="flaticon-loupe"></i>
                      </button>
                    </form>
                  </div>
                  <div className="tgmobile__menu-outer"></div>
                  <div className="social-links">
                    <ul className="list-wrap">
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="tgmobile__menu-backdrop"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="search__popup-wrap">
        <div className="search__layer"></div>
        <div className="search__close">
          <span>
            <i className="flaticon-swords-in-cross-arrangement"></i>
          </span>
        </div>
        <div className="search__wrap text-center">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="title">
                  ...<span>Search Here</span> ...
                </h2>
                <div className="search__form">
                  <form action="#">
                    <input
                      type="text"
                      name="search"
                      placeholder="Type keywords here"
                      required
                    />
                    <button className="search-btn">
                      <i className="flaticon-loupe"></i>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="offCanvas__wrap">
        <div className="offCanvas__body">
          <div className="offCanvas__top">
            <div className="offCanvas__logo logo">
              <a href="index.html">
                {/* <img src="img/logo/logo.png" alt="Logo" /> */}
                <img src={LogoHeader} alt="Logo" />
              </a>
            </div>
            <div className="offCanvas__toggle">
              <i className="flaticon-swords-in-cross-arrangement"></i>
            </div>
          </div>
          <div className="offCanvas__content">
            <h2 className="title">
              Best Seller of Month Ideas for <span>NFT Wallet</span>
            </h2>
            <div className="offCanvas__contact">
              <h4 className="small-title">CONTACT US</h4>
              <ul className="offCanvas__contact-list list-wrap">
                <li>
                  <a href="tel:93332225557">+9 333 222 5557</a>
                </li>
                <li>
                  <a href="mailto:info@webmail.com">info@webmail.com</a>
                </li>
                <li>New Central Park W7 Street ,New York</li>
              </ul>
            </div>
            <div className="offCanvas__newsletter">
              <h4 className="small-title">Subscribe</h4>
              <form action="#" className="offCanvas__newsletter-form">
                <input type="email" placeholder="Get News & Updates" />
                <button type="submit">
                  <i className="flaticon-send"></i>
                </button>
              </form>
              <p>
                Subscribe dolor sitamet, consectetur adiping eli. Duis esollici
                tudin augue.
              </p>
            </div>
            <ul className="offCanvas__social list-wrap">
              <li>
                <a href="#">
                  <i className="fab fa-twitter"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fab fa-youtube"></i>
                </a>
              </li>
            </ul>
          </div>
          <div className="offCanvas__copyright">
            <p>
              Copyright © 2023 - By <span>VFX BAZAAR</span>
            </p>
          </div>
        </div>
      </div>
      <div className="offCanvas__overlay"></div>
    </header>
  );
}
export default Header;
