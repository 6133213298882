import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import Home from './components/Home';
import Animation from "./components/NavbarComponents/Animation";
import Gaming from "./components/NavbarComponents/Gaming";
import Producer from "./components/NavbarComponents/Producer";
import ProductionSupport from "./components/NavbarComponents/ProductionSupport";
import ResearchAndDevelopment from "./components/NavbarComponents/ResearchAndDevelopment";
import Vfx from "./components/NavbarComponents/Vfx";
import ProductSingle from "./components/ProductSingle";
import Login from "./components/Login";
import ForgetPassword from "./components/ForgetPassword";
import SignUp from "./components/SignUp";

// import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    {/* <Router> */}
    {/* <App /> */}
    {/* </Router> */}

    <BrowserRouter>
      {/* <BrowserRouter basename="/create-react-vfx">   */}
      <Routes>
        <Route exact path="/" element={<App />} />
        <Route path="/animation" element={<Animation />} />
        <Route path="/vfx" element={<Vfx />} />
        <Route path="/gaming" element={<Gaming />} />
        <Route path="/producer" element={<Producer />} />
        <Route path="/productionsupport" element={<ProductionSupport />} />
        <Route path="/r&d" element={<ResearchAndDevelopment />} />
        <Route path="/productSingle" element={<ProductSingle />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgetpassword" element={<ForgetPassword />} />
        <Route path="/signup" element={<SignUp />} />

        <Route
          path="*"
          element={<div> Not Found or You do not have permission.</div>}
        />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
