import React from "react";
import about_tab01 from "../../src/img/others/about_tab01.png";
import about_tab02 from "../../src/img/others/about_tab02.png";
import about_tab03 from "../../src/img/others/about_tab03.png";
import about_tab04 from "../../src/img/others/about_tab04.png";
import about_tab05 from "../../src/img/others/about_tab05.png";
import about_tab06 from "../../src/img/others/about_tab06.png";
import about_img01 from "../../src/img/others/about_img01.jpg";
import about_img02 from "../../src/img/others/about_img02.jpg";
import about_img03 from "../../src/img/others/about_img03.jpg";
import about_img04 from "../../src/img/others/about_img04.jpg";
import about_img05 from "../../src/img/others/about_img05.jpg";
import about_img06 from "../../src/img/others/about_img06.jpg";

function SectionFour() {
  return (
    <div className="area-background" data-background="img/bg/area_bg01.jpg">
      <section className="about__areaa section-pt-130 section-pb-130">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-7 col-md-10">
              <div className="section__title text-center mb-60">
                <span className="sub-title tg__animate-text">
                  know about us
                </span>
                <h3 className="title">OUR CLIENT FEEDBACKS</h3>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-xl-10">
              <div className="about__tab-wrap">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="about01-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#about01"
                      type="button"
                      role="tab"
                      aria-controls="about01"
                      aria-selected="true"
                    >
                      <span className="img-shape"></span>
                      {/* <img src="img/others/about_tab01.png" alt="img" /> */}
                      <img src= {about_tab01} alt="img" />
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="about02-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#about02"
                      type="button"
                      role="tab"
                      aria-controls="about02"
                      aria-selected="false"
                    >
                      <span className="img-shape"></span>
                      {/* <img src="img/others/about_tab02.png" alt="img" /> */}
                      <img src= {about_tab02} alt="img" />
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="about03-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#about03"
                      type="button"
                      role="tab"
                      aria-controls="about03"
                      aria-selected="false"
                    >
                      <span className="img-shape"></span>
                      {/* <img src="img/others/about_tab03.png" alt="img" /> */}
                      <img src= {about_tab03} alt="img" />
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="about04-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#about04"
                      type="button"
                      role="tab"
                      aria-controls="about04"
                      aria-selected="false"
                    >
                      <span className="img-shape"></span>
                      {/* <img src="img/others/about_tab04.png" alt="img" /> */}
                      <img src= {about_tab04}alt="img" />
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="about05-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#about05"
                      type="button"
                      role="tab"
                      aria-controls="about05"
                      aria-selected="false"
                    >
                      <span className="img-shape"></span>
                      {/* <img src="img/others/about_tab05.png" alt="img" /> */}
                      <img src= {about_tab05}alt="img" />
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="about06-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#about06"
                      type="button"
                      role="tab"
                      aria-controls="about06"
                      aria-selected="false"
                    >
                      <span className="img-shape"></span>
                      {/* <img src="img/others/about_tab06.png" alt="img" /> */}
                      <img src= {about_tab06} alt="img" />
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane show active"
              id="about01"
              role="tabpanel"
              aria-labelledby="about01-tab"
            >
              <div className="col-xl-10 m-auto">
                <div className="row justify-content-center">
                  <div className="col-xl-5 col-lg-10">
                    <div className="about__img">
                      {/* <img src="img/others/about_img01.jpg" alt="img" /> */}
                      <img src= {about_img01} alt="img" />
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-10">
                    <div className="about__flex-wrap">
                      <div className="about__content-wrap">
                        <div className="about__content">
                          <h4 className="title">human game</h4>
                          <span className="rate">rate 50%</span>
                          <p>
                            Lorem ipsum dolor sit amet, consteur adipiscing Duis
                            elementum sollicitudin is yaugue euismods Nulla
                            ullamcorper. Morbi pharetra tellus miolslis,
                            tincidunt massa venenatis.
                          </p>
                          <br />
                          <div className="team__content">
                            <h4 className="name">
                              <a href="#">killer master</a>
                            </h4>
                            <span className="designation">Vfx Director </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane"
              id="about02"
              role="tabpanel"
              aria-labelledby="about02-tab"
            >
              <div className="col-xl-10 m-auto">
                <div className="row justify-content-center">
                  <div className="col-xl-5 col-lg-10">
                    <div className="about__img">
                      {/* <img src="img/others/about_img02.jpg" alt="img" /> */}
                      <img src= {about_img02}alt="img" />
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-10">
                    <div className="about__flex-wrap">
                      <div className="about__content-wrap">
                        <div className="about__content">
                          <h4 className="title">Axie Infinity</h4>
                          <span className="rate">rate 60%</span>
                          <p>
                            Lorem ipsum dolor sit amet, consteur adipiscing Duis
                            elementum sollicitudin is yaugue euismods Nulla
                            ullamcorper. Morbi pharetra tellus miolslis,
                            tincidunt massa venenatis.
                          </p>
                          <br />
                          <div className="team__content">
                            <h4 className="name">
                              <a href="#">killer master</a>
                            </h4>
                            <span className="designation">Vfx Director </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="tab-pane"
              id="about03"
              role="tabpanel"
              aria-labelledby="about03-tab"
            >
              <div className="col-xl-10 m-auto">
                <div className="row justify-content-center">
                  <div className="col-xl-5 col-lg-10">
                    <div className="about__img">
                      {/* <img src="img/others/about_img03.jpg" alt="img" /> */}
                      <img src= {about_img03}alt="img" />
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-10">
                    <div className="about__flex-wrap">
                      <div className="about__content-wrap">
                        <div className="about__content">
                          <h4 className="title">The Walking Dead</h4>
                          <span className="rate">rate 75%</span>
                          <p>
                            Lorem ipsum dolor sit amet, consteur adipiscing Duis
                            elementum sollicitudin is yaugue euismods Nulla
                            ullamcorper. Morbi pharetra tellus miolslis,
                            tincidunt massa venenatis.
                          </p>
                          <br />
                          <div className="team__content">
                            <h4 className="name">
                              <a href="#">killer master</a>
                            </h4>
                            <span className="designation">Vfx Director </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane"
              id="about04"
              role="tabpanel"
              aria-labelledby="about04-tab"
            >
              <div className="col-xl-10 m-auto">
                <div className="row justify-content-center">
                  <div className="col-xl-5 col-lg-10">
                    <div className="about__img">
                      {/* <img src="img/others/about_img04.jpg" alt="img" /> */}
                      <img src= {about_img04}alt="img" />
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-10">
                    <div className="about__flex-wrap">
                      <div className="about__content-wrap">
                        <div className="about__content">
                          <h4 className="title">The Dogami</h4>
                          <span className="rate">rate 65%</span>
                          <p>
                            Lorem ipsum dolor sit amet, consteur adipiscing Duis
                            elementum sollicitudin is yaugue euismods Nulla
                            ullamcorper. Morbi pharetra tellus miolslis,
                            tincidunt massa venenatis.
                          </p>
                          <br />
                          <div className="team__content">
                            <h4 className="name">
                              <a href="#">killer master</a>
                            </h4>
                            <span className="designation">Vfx Director </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane"
              id="about05"
              role="tabpanel"
              aria-labelledby="about05-tab"
            >
              <div className="col-xl-10 m-auto">
                <div className="row justify-content-center">
                  <div className="col-xl-5 col-lg-10">
                    <div className="about__img">
                      {/* <img src="img/others/about_img05.jpg" alt="img" /> */}
                      <img src= {about_img05} alt="img" />
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-10">
                    <div className="about__flex-wrap">
                      <div className="about__content-wrap">
                        <div className="about__content">
                          <h4 className="title">The Sandbox</h4>
                          <span className="rate">rate 75%</span>
                          <p>
                            Lorem ipsum dolor sit amet, consteur adipiscing Duis
                            elementum sollicitudin is yaugue euismods Nulla
                            ullamcorper. Morbi pharetra tellus miolslis,
                            tincidunt massa venenatis.
                          </p>
                          <br />
                          <div className="team__content">
                            <h4 className="name">
                              <a href="#">killer master</a>
                            </h4>
                            <span className="designation">Vfx Director </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane"
              id="about06"
              role="tabpanel"
              aria-labelledby="about06-tab"
            >
              <div className="col-xl-10 m-auto">
                <div className="row justify-content-center">
                  <div className="col-xl-5 col-lg-10">
                    <div className="about__img">
                      {/* <img src="img/others/about_img06.jpg" alt="img" /> */}
                      <img src={about_img06} alt="img" />
                    </div>
                  </div>
                  <div className="col-xl-7 col-lg-10">
                    <div className="about__flex-wrap">
                      <div className="about__content-wrap">
                        <div className="about__content">
                          <h4 className="title">Pegaxy Horses</h4>
                          <span className="rate">rate 85%</span>
                          <p>
                            Lorem ipsum dolor sit amet, consteur adipiscing Duis
                            elementum sollicitudin is yaugue euismods Nulla
                            ullamcorper. Morbi pharetra tellus miolslis,
                            tincidunt massa venenatis.
                          </p>
                          <br />
                          <div className="team__content">
                            <h4 className="name">
                              <a href="#">killer master</a>
                            </h4>
                            <span className="designation">Vfx Director </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SectionFour;
