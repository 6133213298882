import React from "react";
import Header from "../Header";
import Footer from "../footer";
import nft_img03 from "../../img/nft/nft_img03.jpg";
import nft_img02 from "../../img/nft/nft_img02.jpg";
// import nft_img03 from "../../img/nft/nft_img03.jpg";
import nft_img04 from "../../img/nft/nft_img04.jpg";

const Gaming = () => {
  return (
    <main className="main--area">
      <Header />

      {/* breadcrumb-area  */}
      <section
        className="breadcrumb-area breadcrumb__hide-img"
        data-background="assets/img/bg/breadcrumb_bg02.jpg"
      >
        <div className="container">
          <div className="breadcrumb__wrapper">
            <div className="row">
              <div className="col-12">
                <div className="breadcrumb__content">
                  <h2 className="title">Gaming</h2>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">Home</li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Gaming
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* breadcrumb-area-end  */}

      {/* shop-area */}
      <section className="shop-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-3 col-lg-4 col-md-11 order-2 order-lg-0">
              <aside className="shop-sidebar">
                <div className="shop__widget">
                  <h4 className="shop__widget-title">Filter</h4>
                  <div className="shop__widget-inner">
                    <div
                      className="accordion accordion-flush"
                      id="accordionFlushExample"
                    >
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingOne">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseOne"
                            aria-expanded="false"
                            aria-controls="flush-collapseOne"
                          >
                            Delivery Time
                          </button>
                        </h2>
                       
                        <div
                          id="flush-collapseOne"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-headingOne"
                          data-bs-parent="#accordionFlushExample"
                        >
                           <div
                          className="btn-group-vertical"
                          role="group"
                          aria-label="Basic checkbox toggle button group"
                        >
                          <input
                            type="checkbox"
                            className="btn-check"
                            id="btncheck1"
                            autocomplete="off"
                          />
                          <label
                            className="btn btn-outline-primary"
                            for="btncheck1"
                          >
                            Within 1 day
                          </label>

                          <input
                            type="checkbox"
                            className="btn-check"
                            id="btncheck2"
                            autocomplete="off"
                          />
                          <label
                            className="btn btn-outline-primary"
                            for="btncheck2"
                          >
                            Within 2 day

                          </label>

                          <input
                            type="checkbox"
                            className="btn-check"
                            id="btncheck3"
                            autocomplete="off"
                          />
                          <label
                            className="btn btn-outline-primary"
                            for="btncheck3"
                          >
                            Within 4 day

                          </label>
                        </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingTwo">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseTwo"
                            aria-expanded="false"
                            aria-controls="flush-collapseTwo"
                          >
                            Headphone
                          </button>
                        </h2>
                        <div
                          id="flush-collapseTwo"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-headingTwo"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body">
                            Placeholder content for this accordion, which is
                            intended to demonstrate the{" "}
                            <code>.accordion-flush</code> class. This is the
                            second item's accordion body. Let's imagine this
                            being filled with some actual content.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2
                          className="accordion-header"
                          id="flush-headingThree"
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseThree"
                            aria-expanded="false"
                            aria-controls="flush-collapseThree"
                          >
                            TOURNAMENTS
                          </button>
                        </h2>
                        <div
                          id="flush-collapseThree"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-headingThree"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body">
                            Placeholder content for this accordion, which is
                            intended to demonstrate the{" "}
                            <code>.accordion-flush</code> class. This is the
                            third item's accordion body. Nothing more exciting
                            happening here in terms of content, but just filling
                            up the space to make it look, at least at first
                            glance, a bit more representative of how this would
                            look in a real-world application.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingFour">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseFour"
                            aria-expanded="false"
                            aria-controls="flush-collapseFour"
                          >
                            E-SPORTS
                          </button>
                        </h2>
                        <div
                          id="flush-collapseFour"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-headingFour"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body">
                            Placeholder content for this accordion, which is
                            intended to demonstrate the{" "}
                            <code>.accordion-flush</code> class. This is the
                            third item's accordion body. Nothing more exciting
                            happening here in terms of content, but just filling
                            up the space to make it look, at least at first
                            glance, a bit more representative of how this would
                            look in a real-world application.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="shop__widget">
                            <h4 className="shop__widget-title" >Categories</h4>
                            <div className="shop__widget-inner">
                                <ul className="product-categories list-wrap">
                                    <li><a href="#">controller</a><span className="float-right">12</span></li>
                                    <li><a href="#">Headphone</a><span className="float-right">03</span></li>
                                    <li><a href="#">TOURNAMENTS</a><span className="float-right">18</span></li>
                                    <li><a href="#">E-SPORTS</a><span className="float-right">05</span></li>
                                </ul>
                            </div>
                        </div> */}

                <div className="shop__widget">
                  <h4 className="shop__widget-title">Related products</h4>
                  <div className="shop__widget-inner">
                    <div className="related__products-item">
                      <div className="related__products-thumb">
                        <a href="#">
                          {/* <img src="img/nft/nft_img04.jpg" alt="img" /> */}
                          <img src= {nft_img03} alt="img" />
                        </a>
                      </div>
                      <div className="related__products-content">
                        <h4 className="product-name">
                          <a href="#">Headphone</a>
                        </h4>
                        <span className="amount">$350</span>
                      </div>
                    </div>
                    <div className="related__products-item">
                      <div className="related__products-thumb">
                        <a href="#">
                          {/* <img src="img/nft/nft_img04.jpg" alt="img" /> */}
                          <img src= {nft_img02} alt="img" />
                        </a>
                      </div>
                      <div className="related__products-content">
                        <h4 className="product-name">
                          <a href="#">Replika Axe</a>
                        </h4>
                        <span className="amount">$230</span>
                      </div>
                    </div>
                    <div className="related__products-item">
                      <div className="related__products-thumb">
                        <a href="#">
                        <img src= {nft_img03} alt="img" />
                        </a>
                      </div>
                      <div className="related__products-content">
                        <h4 className="product-name">
                          <a href="#">Ultima Axe</a>
                        </h4>
                        <span className="amount">$120</span>
                      </div>
                    </div>
                  </div>
                </div>
              </aside>
            </div>
            <div className="col-xl-9 col-lg-8 col-md-11">
              <div className="shop__top-wrap">
                <div className="row align-items-center">
                  <div className="col-lg-8 col-sm-6">
                    <div className="shop__showing-result">
                      <p>Showing 1 - 9 of 15 results</p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-6">
                    <div className="shop__ordering">
                      <select name="orderby" className="orderby">
                        <option value="Default sorting">Default sorting</option>
                        <option value="Sort by popularity">
                          Sort by popularity
                        </option>
                        <option value="Sort by average rating">
                          Sort by average rating
                        </option>
                        <option value="Sort by latest">Sort by latest</option>
                        <option value="Sort by latest">Sort by latest</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center row-cols-xl-3 row-cols-lg-2 row-cols-md-2 row-cols-sm-2 row-cols-1">
                <div className="col">
                  <div className="shop__item">
                    <div className="shop__item-thumb">
                      <a href="#">
                      <img src= {nft_img02} alt="img" />
                      </a>
                      <a href="#" className="wishlist-button">
                        <i className="far fa-heart"></i>
                      </a>
                    </div>
                    <div className="shop__item-line"></div>
                    <div className="shop__item-content">
                      <div className="shop__item-content-top">
                        <h4 className="title">
                          <a href="#">Nintendo Switch</a>
                        </h4>
                        <div className="shop__item-price">$29</div>
                      </div>
                      <div className="shop__item-cat">
                        <a href="#">E-SPORTS</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="shop__item">
                    <div className="shop__item-thumb">
                      <a href="#">
                      <img src= {nft_img03} alt="img" />
                      </a>
                      <a href="#" className="wishlist-button">
                        <i className="far fa-heart"></i>
                      </a>
                    </div>
                    <div className="shop__item-line"></div>
                    <div className="shop__item-content">
                      <div className="shop__item-content-top">
                        <h4 className="title">
                          <a href="#">Headphone</a>
                        </h4>
                        <div className="shop__item-price">$69</div>
                      </div>
                      <div className="shop__item-cat">
                        <a href="#">accessories</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="shop__item">
                    <div className="shop__item-thumb">
                      <a href="#">
                      <img src= {nft_img02} alt="img" />
                      </a>
                      <a href="#" className="wishlist-button">
                        <i className="far fa-heart"></i>
                      </a>
                    </div>
                    <div className="shop__item-line"></div>
                    <div className="shop__item-content">
                      <div className="shop__item-content-top">
                        <h4 className="title">
                          <a href="#">replica Axe</a>
                        </h4>
                        <div className="shop__item-price">$39</div>
                      </div>
                      <div className="shop__item-cat">
                        <a href="#">E-SPORTS</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="shop__item">
                    <div className="shop__item-thumb">
                      <a href="#">
                      <img src= {nft_img03} alt="img" />
                      </a>
                      <a href="#" className="wishlist-button">
                        <i className="far fa-heart"></i>
                      </a>
                    </div>
                    <div className="shop__item-line"></div>
                    <div className="shop__item-content">
                      <div className="shop__item-content-top">
                        <h4 className="title">
                          <a href="#">ps5 controller</a>
                        </h4>
                        <div className="shop__item-price">$49</div>
                      </div>
                      <div className="shop__item-cat">
                        <a href="#">accessories</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="shop__item">
                    <div className="shop__item-thumb">
                      <a href="#">
                      <img src= {nft_img02} alt="img" />
                      </a>
                      <a href="#" className="wishlist-button">
                        <i className="far fa-heart"></i>
                      </a>
                    </div>
                    <div className="shop__item-line"></div>
                    <div className="shop__item-content">
                      <div className="shop__item-content-top">
                        <h4 className="title">
                          <a href="#">Golden Crown</a>
                        </h4>
                        <div className="shop__item-price">$19</div>
                      </div>
                      <div className="shop__item-cat">
                        <a href="#">gaming</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="shop__item">
                    <div className="shop__item-thumb">
                      <a href="#">
                      <img src= {nft_img03} alt="img" />
                      </a>
                      <a href="#" className="wishlist-button">
                        <i className="far fa-heart"></i>
                      </a>
                    </div>
                    <div className="shop__item-line"></div>
                    <div className="shop__item-content">
                      <div className="shop__item-content-top">
                        <h4 className="title">
                          <a href="#">gaming mouse</a>
                        </h4>
                        <div className="shop__item-price">$49</div>
                      </div>
                      <div className="shop__item-cat">
                        <a href="#">accessories</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="shop__item">
                    <div className="shop__item-thumb">
                      <a href="#">
                      <img src= {nft_img02} alt="img" />
                      </a>
                      <a href="#" className="wishlist-button">
                        <i className="far fa-heart"></i>
                      </a>
                    </div>
                    <div className="shop__item-line"></div>
                    <div className="shop__item-content">
                      <div className="shop__item-content-top">
                        <h4 className="title">
                          <a href="#">Headphone - X</a>
                        </h4>
                        <div className="shop__item-price">$29</div>
                      </div>
                      <div className="shop__item-cat">
                        <a href="#">accessories</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="shop__item">
                    <div className="shop__item-thumb">
                      <a href="#">
                      <img src= {nft_img03} alt="img" />
                      </a>
                      <a href="#" className="wishlist-button">
                        <i className="far fa-heart"></i>
                      </a>
                    </div>
                    <div className="shop__item-line"></div>
                    <div className="shop__item-content">
                      <div className="shop__item-content-top">
                        <h4 className="title">
                          <a href="#">replica gun</a>
                        </h4>
                        <div className="shop__item-price">$59</div>
                      </div>
                      <div className="shop__item-cat">
                        <a href="#">E-SPORTS</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="shop__item">
                    <div className="shop__item-thumb">
                      <a href="#">
                      <img src= {nft_img02} alt="img" />
                      </a>
                      <a href="#" className="wishlist-button">
                        <i className="far fa-heart"></i>
                      </a>
                    </div>
                    <div className="shop__item-line"></div>
                    <div className="shop__item-content">
                      <div className="shop__item-content-top">
                        <h4 className="title">
                          <a href="#">gun robot</a>
                        </h4>
                        <div className="shop__item-price">$109</div>
                      </div>
                      <div className="shop__item-cat">
                        <a href="#">E-SPORTS</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pagination__wrap">
                <ul className="list-wrap d-flex flex-wrap justify-content-center">
                  <li>
                    <a href="#" className="page-numbers">
                      01
                    </a>
                  </li>
                  <li>
                    <span className="page-numbers current">02</span>
                  </li>
                  <li>
                    <a href="#" className="page-numbers">
                      03
                    </a>
                  </li>
                  <li>
                    <a href="#" className="page-numbers">
                      ....
                    </a>
                  </li>
                  <li>
                    <a href="#" className="next page-numbers">
                      <i className="fas fa-angle-double-right"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* shop-area-end */}
      <Footer />
    </main>
  );
};

export default Gaming;
