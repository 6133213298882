import React from "react";
import Header from "./Header";
import Footer from "./footer";
import { Link } from "react-router-dom";

const Login = () => {
  return (
    <>
      <Header />

      <main className="main--area">
        {/* <!-- breadcrumb-area --> */}
        <section
          className="breadcrumb-area breadcrumb__hide-img"
          data-background="assets/img/bg/breadcrumb_bg02.jpg"
        >
          <div className="container">
            <div className="breadcrumb__wrapper">
              <div className="row">
                <div className="col-12">
                  <div className="breadcrumb__content">
                    <h2 className="title">Login</h2>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link to="/">Home</Link>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Login
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- breadcrumb-area-end --> */}

        {/* <!-- contact-area --> */}
        <section className="contact-area">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-3"></div>
              <div className="col-lg-6">
                <div className="contact__form-wrap">
                  <form
                    id="contact-form"
                    action="https://themedox.com/demo/mykd/assets/mail.php"
                    method="POST"
                  >
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="input-grp">
                          <input
                            name="email"
                            type="email"
                            placeholder="Email *"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="input-grp">
                          <input
                            name="password"
                            type="Password"
                            placeholder="Password"
                            required
                          />
                        </div>
                      </div>

                      <div className="col-sm-12">
                        <div className="row">
                          <div className="input-chk col-6">
                            <input
                              type="checkbox"
                              id="remember"
                              name="remember"
                              value="remember"
                            />
                            <label for="remember">Remember Me</label>
                          </div>
                          <div className="input-frg col-6 text-end">
                            <Link to="/forgetpassword">Forgot Password</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />

                    <button className="submit-btn">Login</button>
                  </form>
                  <p className="ajax-response"></p>
                  <br />
                  <p className="text-center">
                    Don't have an account <a href="#">Sign Up</a>
                  </p>
                  <div className="login__separator">or</div>
                </div>
                <div className="row social-cnt">
                  <div className="col-sm-6 fb">
                    <button
                      className="btn facebook-btn social-btn"
                      type="button"
                    >
                      <span>
                        <i className="fab fa-facebook-f"></i>&nbsp; Log In with
                        Facebook
                      </span>{" "}
                    </button>
                  </div>
                  <div className="col-sm-6 gl">
                    <button className="btn google-btn social-btn" type="button">
                      <span>
                        <i className="fab fa-google"></i>&nbsp; Log In with
                        Google
                      </span>{" "}
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-3"></div>
            </div>
          </div>
        </section>
        {/* <!-- contact-area-end --> */}
      </main>

      <Footer />
    </>
  );
};

export default Login;
