import React from "react";
function SectionSix () {

return(
<div>
<section className="video__area video-bg tg-jarallax" data-background="img/bg/video_bg.jpg">
              <div className="container">
                  <div className="row justify-content-center">
                      <div className="col-xl-6 col-lg-8 col-md-11">
                          <div className="video__content text-center">
                              <a data-bs-toggle="modal" data-bs-target="#video-popup" className="popup-video"><i className="flaticon-play"></i></a>
                              <h2 className="title">JOIN THE <span>COMMUNITY</span></h2>
                              <p>Join our VFX community and choosec on step</p>
                              <a href="#" className="video__btn tg-btn-2"><span>join Now</span></a>
                          </div>
                      </div>
                  </div>
              </div>
             
          </section>

          <div className="modal fade" id="video-popup" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-xl">
    <div className="modal-content bg-black">
<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
   <div className="modal-body p-0">
      <div className="embed-responsive embed-responsive-16by9">
  <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/bB8KO6N0Jx0?autoplay=1" style={{'width': '100% ' , 'height': '80vh'}} allowFullScreen></iframe>
</div>
      </div>
    
    </div>
  </div>
</div>


</div>
);

}

export default SectionSix