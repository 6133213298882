import React from "react";
import nft_img01 from "../../src/img/nft/nft_img01.jpg";
import nft_img02 from "../../src/img/nft/nft_img02.jpg";
import nft_img03 from "../../src/img/nft/nft_img03.jpg";




function SectionTwo () {
return(
    <section className="nft-item__area">
              <div className="container custom-container">
        
        <div className="row justify-content-center">
                      <div className="col-xl-6 col-lg-7 col-md-10">
                          <div className="section__title text-center mb-60">
                              <span className="sub-title tg__animate-text">know about us</span>
                              <h3 className="title">Popular Categories</h3>
                          </div>
                      </div>
                  </div>
                  <div className="row justify-content-center">
                      <div className="col-xxl-4 col-xl-5 col-lg-6 col-md-9">
                          <div className="nft-item__box">
                              <div className="nft-item__thumb">
                                  {/* <a href="#"><img src="img/nft/nft_img01.jpg" alt="img" /></a> */}
                                  <a href="#"><img src= {nft_img01} alt="img" /></a>
                              </div>
                              <div className="nft-item__content">
                                  <h4 className="title"><a href="#">VFX Effects</a></h4>
                                  <div className="nft-item__avatar">
                                  <p className="mb-0">VFX in film is a process of creating imagery... </p>
                                      
                                  </div>
                                  <div className="nft-item__bid">
                                      <div className="nft-item__price">
                                          <a href="#" className="bid-btn">Read More <i className="fas fa-long-arrow-alt-right"></i></a>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="col-xxl-4 col-xl-5 col-lg-6 col-md-9">
                          <div className="nft-item__box">
                              <div className="nft-item__thumb">
                                  {/* <a href="#"><img src="img/nft/nft_img02.jpg" alt="img" /></a> */}
                                  <a href="#"><img src= {nft_img02} alt="img" /></a>
                              </div>
                              <div className="nft-item__content">
                                  <h4 className="title"><a href="shop-details.html">Gaming</a></h4>
                                  <div className="nft-item__avatar">
                                      <p className="mb-0"> Explore real time game visual effects...</p>
                                  </div>
                                  <div className="nft-item__bid">
                                      <div className="nft-item__price">
                                         <a href="#" className="bid-btn">Read More <i className="fas fa-long-arrow-alt-right"></i></a>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="col-xxl-4 col-xl-5 col-lg-6 col-md-9">
                          <div className="nft-item__box">
                              <div className="nft-item__thumb">
                                  {/* <a href="shop-details.html"><img src="img/nft/nft_img03.jpg" alt="img" /></a> */}
                                  <a href="shop-details.html"><img src= {nft_img03} alt="img" /></a>
                              </div>
                              <div className="nft-item__content">
                                  <h4 className="title"><a href="shop-details.html">R & D</a></h4>
                                  <div className="nft-item__avatar">
                                      <p className="mb-0">R&D plays a critical role within the innovation...</p>
                                  </div>
                                  <div className="nft-item__bid">
                                      <div className="nft-item__price">
                                          <a href="#" className="bid-btn">Read More <i className="fas fa-long-arrow-alt-right"></i></a>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>



);



}

export default SectionTwo