import React from "react";
import social_icon01 from "../../src/img/icons/social_icon01.png";
import social_icon02 from "../../src/img/icons/social_icon02.png";
import social_icon03 from "../../src/img/icons/social_icon03.png";
import social_icon04 from "../../src/img/icons/social_icon04.png";
import logoFooter from "../../src/img/logo/logo.png";
import paymentCard from "../../src/img/others/payment_card.png";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="footer-style-one">
      <div className="footer__top-wrap">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-5 col-md-7">
              <div className="footer-widget">
                <div className="footer-logo logo">
                  {/* <a href="index.html"><img src="img/logo/logo.png" alt="Logo" /></a> */}
                  <a href="index.html">
                    <img src={logoFooter} alt="Logo" />
                  </a>
                </div>
                <div className="footer-text">
                  <p className="desc">
                    Lorem ipsum dolor sitamet consectur adipiscing Duis esollici
                    tudin augue euismod. Nulla ullam dolor sitamet consectetur
                  </p>
                  <p className="social-title">
                    Active{" "}
                    <span>
                      With Us <i className="fas fa-angle-double-right"></i>
                    </span>
                  </p>
                  <div className="footer-social">
                    <a href="#">
                      <img src={social_icon01} alt="iocn" />
                    </a>
                    <a href="#">
                      <img src={social_icon02} alt="iocn" />
                    </a>
                    <a href="#">
                      <img src={social_icon03} alt="iocn" />
                    </a>
                    <a href="#">
                      <img src={social_icon04} alt="iocn" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-5 col-sm-6">
              <div className="footer-widget widget_nav_menu">
                <h4 className="fw-title">quick link</h4>
                <ul className="list-wrap menu">
                  <li>
                    <Link to="/animation">Animation</Link>
                  </li>
                  <li>
                    <Link to="/vfx">VFX</Link>
                  </li>
                  <li>
                    <Link to="/gaming">Gaming</Link>
                  </li>
                  <li>
                    <Link to="/producer">Producer</Link>
                  </li>
                  <li>
                    <Link to="/productionsupport">Production Support</Link>
                  </li>
                  <li>
                    <Link to="/r&d">R&D</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-5 col-sm-6">
              <div className="footer-widget widget_nav_menu">
                <h4 className="fw-title">Supports</h4>
                <ul className="list-wrap menu">
                  <li>
                    <a href="#">Setting & Privacy</a>
                  </li>
                  <li>
                    <a href="#">Help & Support</a>
                  </li>
                  <li>
                    <a href="#">Live Auctions</a>
                  </li>
                  <li>
                    <a href="#">Item Details</a>
                  </li>
                  <li>
                    <a href="#">24/7 Supports</a>
                  </li>
                  <li>
                    <a href="#">Our News</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-4 col-lg-5 col-md-7">
              <div className="footer-widget">
                <h4 className="fw-title">Newsletter</h4>
                <div className="footer-newsletter">
                  <p>
                    Subscribe our newsletter to get our latest update &
                    newsconsectetur
                  </p>
                  <form action="#" className="footer-newsletter-form">
                    <input type="email" placeholder="Your email address" />
                    <button type="submit">
                      <i className="flaticon-paper-plane"></i>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright__wrap">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7">
              <div className="copyright__text">
                <p>
                  Copyright © 2023 - All Rights Reserved By{" "}
                  <span>VFX BAZAAR</span>
                </p>
              </div>
            </div>
            <div className="col-md-5">
              <div className="copyright__card text-center text-md-end">
                {/* <img src="img/others/payment_card.png" alt="img" /> */}
                <img src={paymentCard} alt="img" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <button className="scroll__top scroll-to-target" data-target="html">
        <i className="flaticon-right-arrow"></i>
      </button>
    </footer>
  );
}

export default Footer;
