import React, { useState } from "react";
import Header from "../Header";
import nft_img06 from "../../img/nft/nft_img06.jpg";
import nft_img04 from "../../img/nft/nft_img04.jpg";
import { Link } from "react-router-dom";

const Card = () => {
  return (
    <div className="col">
      <div className="shop__item">
        <div className="shop__item-thumb">
          <Link to="/ProductSingle">
            <img src={nft_img04} alt="img" />
          </Link>
          <a href="#" className="wishlist-button">
            <i className="far fa-heart"></i>
          </a>
        </div>
        <div className="shop__item-line"></div>
        <div className="shop__item-content">
          <div className="shop__item-content-top">
            <h4 className="title">
              <a href="#">Nintendo Switch</a>
            </h4>
            <div className="shop__item-price">$29</div>
          </div>
          <div className="shop__item-cat">
            <a href="#">E-SPORTS</a>
          </div>
        </div>
      </div>
    </div>
  );
};

const Vfx = () => {
  const [minValue, setMinValue] = useState("");
  const [maxValue, setMaxValue] = useState("");

  return (
    <main className="main--area">
      <Header />

      {/* breadcrumb-area  */}
      <section
        className="breadcrumb-area breadcrumb__hide-img"
        data-background="assets/img/bg/breadcrumb_bg02.jpg"
      >
        <div className="container">
          <div className="breadcrumb__wrapper">
            <div className="row">
              <div className="col-12">
                <div className="breadcrumb__content">
                  <h2 className="title">VFX Services</h2>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">Home</li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        VFX Services
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* breadcrumb-area-end  */}

      {/* shop-area */}

      <section className="shop-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-3 col-lg-4 col-md-11 order-2 order-lg-0">
              <aside className="shop-sidebar">
                <div className="shop__widget">
                  <h4 className="shop__widget-title">Categories</h4>
                  <div className="shop__widget-inner">
                    <ul className="product-categories list-wrap">
                      <li>
                        <a href="#">Animations</a>
                        <span className="float-right">12</span>
                      </li>
                      <li>
                        <a href="#">Film Making</a>
                        <span className="float-right">03</span>
                      </li>
                      <li>
                        <a href="#">Image Editing</a>
                        <span className="float-right">18</span>
                      </li>
                      <li>
                        <a href="#">Gaming</a>
                        <span className="float-right">05</span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="shop__widget">
                  <h4 className="shop__widget-title">Filters</h4>
                  <div className="shop__widget-inner">
                    <div
                      className="accordion accordion-flush"
                      id="accordionFlushExample"
                    >
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingOne">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseOne"
                            aria-expanded="false"
                            aria-controls="flush-collapseOne"
                          >
                            Delivery Times
                          </button>
                        </h2>
                        <div
                          id="flush-collapseOne"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-headingOne"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body">
                            <div className="filters__item">
                              <span className="RadioCheckBox">
                                <input
                                  id="withinDays_0-1"
                                  name="withinDays"
                                  type="radio"
                                  tabIndex="0"
                                  className="radioRadioCheckBox"
                                  value="0-1"
                                />
                                <label className="filters" for="withinDays_0-1">
                                  <span className="label__text">
                                    Within 1 day
                                  </span>
                                  <small className="label__count">518</small>
                                </label>
                              </span>
                            </div>

                            <div className="filters__item">
                              <span className="RadioCheckBox">
                                <input
                                  id="withinDays_0-2"
                                  name="withinDays"
                                  type="radio"
                                  tabIndex="0"
                                  className="radioRadioCheckBox"
                                  value="0-2"
                                />
                                <label className="filters" for="withinDays_0-2">
                                  <span className="label__text">
                                    Within 2 day
                                  </span>
                                  <small className="label__count">518</small>
                                </label>
                              </span>
                            </div>

                            <div className="filters__item">
                              <span className="RadioCheckBox">
                                <input
                                  id="withinDays_0-3"
                                  name="withinDays"
                                  type="radio"
                                  tabIndex="0"
                                  className="radioRadioCheckBox"
                                  value="0-3"
                                />
                                <label className="filters" for="withinDays_0-3">
                                  <span className="label__text">
                                    Within 3 day
                                  </span>
                                  <small className="label__count">518</small>
                                </label>
                              </span>
                            </div>

                            <div className="filters__item">
                              <span className="RadioCheckBox">
                                <input
                                  id="withinDays_0-4"
                                  name="withinDays"
                                  type="radio"
                                  tabIndex="0"
                                  className="radioRadioCheckBox"
                                  value="0-4"
                                />
                                <label className="filters" for="withinDays_0-4">
                                  <span className="label__text">
                                    Within 4 day
                                  </span>
                                  <small className="label__count">518</small>
                                </label>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingTwo">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseTwo"
                            aria-expanded="false"
                            aria-controls="flush-collapseTwo"
                          >
                            Freelancer Country
                          </button>
                        </h2>
                        <div
                          id="flush-collapseTwo"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-headingTwo"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body">
                            <div className="location__input">
                              <svg
                                fill="#69728C"
                                width="24"
                                height="24"
                                viewBox="0 0 1024 1024"
                                className="location__input-icon⤍Location⤚9TIwd"
                              >
                                <path
                                  d="M755.499 695.168l128 128c7.41 7.665 11.977 18.12 11.977 29.641 0 23.564-19.103 42.667-42.667 42.667-11.521 0-21.976-4.567-29.653-11.989l0.012 0.012-128-128c-7.41-7.665-11.977-18.12-11.977-29.641 
	  0-23.564 19.103-42.667 42.667-42.667 11.521 0 21.976 4.567 29.653 11.989l-0.012-0.012zM426.667 85.333c188.513 0 341.333 152.82 341.333 341.333s-152.82 341.333-341.333 341.333v0c-188.513 
	  0-341.333-152.82-341.333-341.333s152.82-341.333 341.333-341.333v0zM426.667 170.667c-141.385 0-256 114.615-256 256s114.615 256 256 256v0c141.385 0 256-114.615 256-256s-114.615-256-256-256v0z"
                                ></path>
                              </svg>
                              <input
                                type="text"
                                id=""
                                name=""
                                className="location__input"
                                value=""
                                autocomplete="new-password"
                                role="combobox"
                                aria-autocomplete="list"
                                aria-owns="react-autowhatever-1"
                                aria-expanded="false"
                                aria-haspopup="false"
                                placeholder="Enter a country"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2
                          className="accordion-header"
                          id="flush-headingThree"
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseThree"
                            aria-expanded="false"
                            aria-controls="flush-collapseThree"
                          >
                            Price
                          </button>
                        </h2>
                        <div
                          id="flush-collapseThree"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-headingThree"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body">
                            <div className="filters__item">
                              <span className="RadioCheckBox">
                                <input
                                  id="withinDays_0-5"
                                  name="withinDays"
                                  type="radio"
                                  tabIndex="0"
                                  className="radioRadioCheckBox"
                                  value="0-5"
                                />
                                <label className="filters" for="withinDays_0-5">
                                  <span className="label__text">Under $20</span>
                                  <small className="label__count">218</small>
                                </label>
                              </span>
                            </div>

                            <div className="filters__item">
                              <span className="RadioCheckBox">
                                <input
                                  id="withinDays_0-6"
                                  name="withinDays"
                                  type="radio"
                                  tabIndex="0"
                                  className="radioRadioCheckBox"
                                  value="0-6"
                                />
                                <label className="filters" for="withinDays_0-6">
                                  <span className="label__text">
                                    $20 to $50
                                  </span>
                                  <small className="label__count">118</small>
                                </label>
                              </span>
                            </div>

                            <div className="filters__item">
                              <span className="RadioCheckBox">
                                <input
                                  id="withinDays_0-7"
                                  name="withinDays"
                                  type="radio"
                                  tabIndex="0"
                                  className="radioRadioCheckBox"
                                  value="0-7"
                                />
                                <label className="filters" for="withinDays_0-7">
                                  <span className="label__text">
                                    $50 to $100
                                  </span>
                                  <small className="label__count">256</small>
                                </label>
                              </span>
                            </div>

                            <div className="filters__item">
                              <span className="RadioCheckBox">
                                <input
                                  id="withinDays_0-8"
                                  name="withinDays"
                                  type="radio"
                                  tabIndex="0"
                                  className="radioRadioCheckBox"
                                  value="0-8"
                                />
                                <label className="filters" for="withinDays_0-8">
                                  <span className="label__text">Over $100</span>
                                  <small className="label__count">85</small>
                                </label>
                              </span>
                            </div>

                            <div className="price__range">
                              <div className="price__range-min">
                                <span className="has-prepended-currency">
                                  <input
                                    type="number"
                                    id=""
                                    name="minPrice"
                                    className="prepended-currency"
                                    placeholder="min"
                                    min="0"
                                    value={minValue}
                                    onChange={(e) =>
                                      setMinValue(e.target.value)
                                    }
                                    step="1"
                                    autocomplete="new-password"
                                  />
                                </span>
                              </div>
                              <div className="price__range-max">
                                <span className="has-prepended-currency">
                                  <input
                                    type="number"
                                    id=""
                                    name="maxPrice"
                                    className="input--full-width"
                                    placeholder="max"
                                    min="0"
                                    value={maxValue}
                                    onChange={(e) =>
                                      setMaxValue(e.target.value)
                                    }
                                    step="1"
                                    autocomplete="new-password"
                                  />
                                </span>
                              </div>
                              <button className="price__set-btn">Set</button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2
                          className="accordion-header"
                          id="flush-headingThree"
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseFour"
                            aria-expanded="false"
                            aria-controls="flush-collapseFour"
                          >
                            More Filters
                          </button>
                        </h2>
                        <div
                          id="flush-collapseFour"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-headingFour"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body">
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="flexSwitchCheckDefault"
                              />
                              <label
                                className="form-check-label"
                                for="flexSwitchCheckDefault"
                              >
                                Recently added
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="shop__widget">
                  <h4 className="shop__widget-title">Related products</h4>
                  <div className="shop__widget-inner">
                    <div className="related__products-item">
                      <div className="related__products-thumb">
                        <a href="#">
                          <img src={nft_img06} alt="img" />
                        </a>
                      </div>
                      <div className="related__products-content">
                        <h4 className="product-name">
                          <a href="#">Animations</a>
                        </h4>
                        <span className="amount">$350</span>
                      </div>
                    </div>
                    <div className="related__products-item">
                      <div className="related__products-thumb">
                        <a href="#">
                          <img src={nft_img04} alt="img" />
                        </a>
                      </div>
                      <div className="related__products-content">
                        <h4 className="product-name">
                          <a href="#">Image Editing</a>
                        </h4>
                        <span className="amount">$230</span>
                      </div>
                    </div>
                    <div className="related__products-item">
                      <div className="related__products-thumb">
                        <a href="#">
                          <img src={nft_img06} alt="img" />
                        </a>
                      </div>
                      <div className="related__products-content">
                        <h4 className="product-name">
                          <a href="#">Film Making</a>
                        </h4>
                        <span className="amount">$120</span>
                      </div>
                    </div>
                  </div>
                </div>
              </aside>
            </div>
            <div className="col-xl-9 col-lg-8 col-md-11">
              <div className="shop__top-wrap">
                <div className="row align-items-center">
                  <div className="col-lg-8 col-sm-6">
                    <div className="shop__showing-result">
                      <p>VFX Categories</p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-6">
                    <div className="shop__ordering">
                      <select name="orderby" className="orderby">
                        <option value="Default sorting">Default sorting</option>
                        <option value="Sort by popularity">
                          Sort by popularity
                        </option>
                        <option value="Sort by average rating">
                          Sort by average rating
                        </option>
                        <option value="Sort by latest">Sort by latest</option>
                        <option value="Sort by latest">Sort by latest</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center row-cols-xl-3 row-cols-lg-2 row-cols-md-2 row-cols-sm-2 row-cols-1">
                {/* <div className="col">
                  <div className="shop__item">
                    <div className="shop__item-thumb">
                      <Link to="/ProductSingle">
                        <img src={nft_img04} alt="img" />
                      </Link>
                      <a href="#" className="wishlist-button">
                        <i className="far fa-heart"></i>
                      </a>
                    </div>
                    <div className="shop__item-line"></div>
                    <div className="shop__item-content">
                      <div className="shop__item-content-top">
                        <h4 className="title">
                          <a href="#">Nintendo Switch</a>
                        </h4>
                        <div className="shop__item-price">$29</div>
                      </div>
                      <div className="shop__item-cat">
                        <a href="#">E-SPORTS</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="shop__item">
                    <div className="shop__item-thumb">
                      <a href="#">
                        <img src={nft_img06} alt="img" />
                      </a>
                      <a href="#" className="wishlist-button">
                        <i className="far fa-heart"></i>
                      </a>
                    </div>
                    <div className="shop__item-line"></div>
                    <div className="shop__item-content">
                      <div className="shop__item-content-top">
                        <h4 className="title">
                          <a href="#">Headphone</a>
                        </h4>
                        <div className="shop__item-price">$69</div>
                      </div>
                      <div className="shop__item-cat">
                        <a href="#">accessories</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="shop__item">
                    <div className="shop__item-thumb">
                      <a href="#">
                        <img src={nft_img04} alt="img" />
                      </a>
                      <a href="#" className="wishlist-button">
                        <i className="far fa-heart"></i>
                      </a>
                    </div>
                    <div className="shop__item-line"></div>
                    <div className="shop__item-content">
                      <div className="shop__item-content-top">
                        <h4 className="title">
                          <a href="#">replica Axe</a>
                        </h4>
                        <div className="shop__item-price">$39</div>
                      </div>
                      <div className="shop__item-cat">
                        <a href="#">E-SPORTS</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="shop__item">
                    <div className="shop__item-thumb">
                      <a href="#">
                        <img src={nft_img06} alt="img" />
                      </a>
                      <a href="#" className="wishlist-button">
                        <i className="far fa-heart"></i>
                      </a>
                    </div>
                    <div className="shop__item-line"></div>
                    <div className="shop__item-content">
                      <div className="shop__item-content-top">
                        <h4 className="title">
                          <a href="#">ps5 controller</a>
                        </h4>
                        <div className="shop__item-price">$49</div>
                      </div>
                      <div className="shop__item-cat">
                        <a href="#">accessories</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="shop__item">
                    <div className="shop__item-thumb">
                      <a href="#">
                        <img src={nft_img04} alt="img" />
                      </a>
                      <a href="#" className="wishlist-button">
                        <i className="far fa-heart"></i>
                      </a>
                    </div>
                    <div className="shop__item-line"></div>
                    <div className="shop__item-content">
                      <div className="shop__item-content-top">
                        <h4 className="title">
                          <a href="#">Golden Crown</a>
                        </h4>
                        <div className="shop__item-price">$19</div>
                      </div>
                      <div className="shop__item-cat">
                        <a href="#">gaming</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="shop__item">
                    <div className="shop__item-thumb">
                      <a href="#">
                        <img src={nft_img06} alt="img" />
                      </a>
                      <a href="#" className="wishlist-button">
                        <i className="far fa-heart"></i>
                      </a>
                    </div>
                    <div className="shop__item-line"></div>
                    <div className="shop__item-content">
                      <div className="shop__item-content-top">
                        <h4 className="title">
                          <a href="#">gaming mouse</a>
                        </h4>
                        <div className="shop__item-price">$49</div>
                      </div>
                      <div className="shop__item-cat">
                        <a href="#">accessories</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="shop__item">
                    <div className="shop__item-thumb">
                      <a href="#">
                        <img src={nft_img04} alt="img" />
                      </a>
                      <a href="#" className="wishlist-button">
                        <i className="far fa-heart"></i>
                      </a>
                    </div>
                    <div className="shop__item-line"></div>
                    <div className="shop__item-content">
                      <div className="shop__item-content-top">
                        <h4 className="title">
                          <a href="#">Headphone - X</a>
                        </h4>
                        <div className="shop__item-price">$29</div>
                      </div>
                      <div className="shop__item-cat">
                        <a href="#">accessories</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="shop__item">
                    <div className="shop__item-thumb">
                      <a href="#">
                        <img src={nft_img04} alt="img" />
                      </a>
                      <a href="#" className="wishlist-button">
                        <i className="far fa-heart"></i>
                      </a>
                    </div>
                    <div className="shop__item-line"></div>
                    <div className="shop__item-content">
                      <div className="shop__item-content-top">
                        <h4 className="title">
                          <a href="#">replica gun</a>
                        </h4>
                        <div className="shop__item-price">$59</div>
                      </div>
                      <div className="shop__item-cat">
                        <a href="#">E-SPORTS</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="shop__item">
                    <div className="shop__item-thumb">
                      <a href="#">
                        <img src={nft_img06} alt="img" />
                      </a>
                      <a href="#" className="wishlist-button">
                        <i className="far fa-heart"></i>
                      </a>
                    </div>
                    <div className="shop__item-line"></div>
                    <div className="shop__item-content">
                      <div className="shop__item-content-top">
                        <h4 className="title">
                          <a href="#">gun robot</a>
                        </h4>
                        <div className="shop__item-price">$109</div>
                      </div>
                      <div className="shop__item-cat">
                        <a href="#">E-SPORTS</a>
                      </div>
                    </div>
                  </div>
                </div> */}
                <Card/>
                <Card/>
                <Card/>
                <Card/>
                <Card/>
                <Card/>
                <Card/>
                <Card/>
                <Card/>
              </div>
              <div className="pagination__wrap">
                <ul className="list-wrap d-flex flex-wrap justify-content-center">
                  <li>
                    <a href="#" className="page-numbers">
                      01
                    </a>
                  </li>
                  <li>
                    <span className="page-numbers current">02</span>
                  </li>
                  <li>
                    <a href="#" className="page-numbers">
                      03
                    </a>
                  </li>
                  <li>
                    <a href="#" className="page-numbers">
                      ....
                    </a>
                  </li>
                  <li>
                    <a href="#" className="next page-numbers">
                      <i className="fas fa-angle-double-right"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* shop-area-end */}
    </main>
  );
};

export default Vfx;
