import React from "react";
import slider_img01 from "../../src/img/slider/slider_img01.png";
import slider_shape01 from "../../src/img/slider/slider_shape01.png";
import slider_shape02 from "../../src/img/slider/slider_shape02.png";
import slider_shape03 from "../../src/img/slider/slider_shape03.png";
import slider_shape04 from "../../src/img/slider/slider_shape04.png";


function SectionOne () {
return(
    <section className="slider__area slider__bg" data-background="img/slider/slider_bg.jpg">
        <div className="slider-activee">
            <div className="single-slider">
                <div className="container custom-container">
                    <div className="row justify-content-between">
                        <div className="col-lg-6">
                            <div className="slider__content">
                                <h6 className="sub-title wow fadeInUp" data-wow-delay=".2s">Welcome to</h6>
                                <h2 className="title wow fadeInUp" data-wow-delay=".5s">VFX Bazaar</h2>
                                <p className="wow fadeInUp" data-wow-delay=".8s">The premier platform for VFX work bidding! Whether you're a client seeking outstanding visual effects for your project or a talented VFX professional looking to showcase your skills, we're here to
connect you and make the bidding process seamless and efficient.</p>
                                <div className="slider__btn wow fadeInUp" data-wow-delay="1.2s">
                                    <a href="#" className="tg-btn-2"><span>contact us</span></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-6 col-xl-5 col-lg-6">
                            <div className="slider__img text-center wow fadeInRight" data-wow-delay=".2s">
                                {/* <img src="img/slider/slider_img01.png" data-magnetic alt="img" /> */}
                                <img src= {slider_img01} data-magnetic alt="img" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="slider__shapes">
            <img src= {slider_shape01} alt="shape" />
            <img src= {slider_shape02} alt="shape" />
            <img src= {slider_shape03} alt="shape" />
            <img src= {slider_shape04} alt="shape" />
        
        </div>

    </section>



);



}

export default SectionOne